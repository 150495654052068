const INFO = {
	main: {
		title: "Glen Lohja | Software Engineer",
		name: "Glen Lohja",
		email: "glenlohja0@gmail.com",
		logo: "../logo.png",
		resumeLink:
			"https://docs.google.com/document/d/1ufgWARbTca7sCmeSAnpii_ELTGoQh0knU_6F79Jw9yI/edit?usp=sharing",
	},

	socials: {
		github: "https://github.com/glenlohja",
		linkedin: "https://www.linkedin.com/in/glenlohja/",
	},

	homepage: {
		title: "Software Engineer",
		description:
			"I am a Software Engineer with a strong expertise in Python and ReactJS, specializing in building scalable, secure, and reliable web applications using a range of frameworks and technologies. With a passion for solving complex problems and a commitment to producing high-quality code that adheres to best practices and industry standards, I continually seek opportunities to expand my skills and tackle new challenges. My enthusiasm for learning and growing as a developer drives me to stay at the forefront of technology and deliver exceptional solutions.",
	},

	about: {
		title: "About me",
		description:
			"I’m a passionate and creative engineer with extensive hands-on experience in software development and a commitment to lifelong learning. Over the past few years, I’ve specialized in designing, developing, and deploying highly responsive applications and e-commerce solutions using a range of advanced technologies.",
		description2: "Expertise:",
		description3: "⚡ Python | Django | Flask | FastAPI | C++",
		description4:
			"⚡JavaScript | ReactJs | Node.js | HTML | CSS | Tailwindcss",
		description5:
			"⚡PostgreSQL | MySQL | MongoDB | Amazon DynamoDB | MariaDB | S3",
		description6:
			"⚡AWS | Azure | Docker | CI/CD | GitHub Actions | Git | Microservices",
		description7:
			"Driven by a passion for solving complex problems and producing high-quality code, I’m always on the lookout for new challenges and opportunities to grow as a developer. Feel free to review my resume and LinkedIn profile to explore more about my professional journey. I’m reachable for any offers at glenlohja0@gmail.com.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Online Store Dashboard",
			description:
				"Developed an interactive business dashboard web page using Python Dash and React. The application was deployed to Azure and featured CI/CD pipelines via GitHub Actions. The dashboard visualizes and analyzes key performance metrics, offering insights into sales, profits, and other essential data.",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "https://www.cdnlogo.com/logos/r/63/react.svg",
			logo3: "https://www.cdnlogo.com/logos/m/40/microsoft-azure.svg",
			linkText: "View Project",
			link: "https://dashboard.glenlohja.com/",
		},
		{
			title: "UPTSupport",
			description:
				"I founded an educational website for Albanian engineering students where they can get free and paid course assignments, lab assignments and other engineering based materials. Tech stack: Bootstrap and Javascript for the front end and Flask for the backend.",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			logo3: "https://cdnlogo.com/logos/a/19/aws.svg",
			linkText: "View Project",
			link: "https://www.uptsupport.tech/",
		},

		{
			title: "Monitor AWS Resources",
			description:
				"A web application developed with FastAPI for the backend and React for the frontend. The application consists of three main pages: one for EC2 resources, one for S3 resources, and one for monitoring using CloudWatch.",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "https://www.cdnlogo.com/logos/r/63/react.svg",
			logo3: "https://cdnlogo.com/logos/a/19/aws.svg",
			linkText: "View Project",
			link: "https://github.com/GlenLohja/FastAPI-Monitor_AWS",
		},
		{
			title: "OneStopMedia",
			description:
				"Digital agency in Albania. Tech stack used is Bootstrap, Javascript and Flask",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			logo3: "https://webapps1.chicago.gov/cdn/Bootstrap-4.0.0-beta.2/assets/brand/bootstrap-social-logo.png",
			linkText: "View Project",
			link: "https://onestop-media.herokuapp.com/",
		},
		{
			title: "Blog",
			description:
				"Blog website with login , register and comments functionality . Using Flask and PostgreSQL for the backend and HTML, CSS & JavaScript for the front end",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			logo3: "https://webapps1.chicago.gov/cdn/Bootstrap-4.0.0-beta.2/assets/brand/bootstrap-social-logo.png",
			linkText: "View Project",
			link: "https://github.com",
		},
		{
			title: "Spotify Time Machine",
			description:
				"Using this project the user can type a date/month/year and a new spotify playlist with the top hits of that day will automatically be added in the spotify account. To retrieve the hits, BeautifulSoup was used for the web scraping.",
			logo1: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo2: "",
			logo3: "",
			linkText: "View Project",
			link: "https://github.com/GlenLohja/Spotify",
		},
	],

	softwareSkills: [
		{
			skillName: "python",
			fontAwesomeClassname: "fab fa-python",
		},
		{
			skillName: "php",
			fontAwesomeClassname: "fab fa-php",
		},
		{
			skillName: "html-5",
			fontAwesomeClassname: "fab fa-html5",
		},
		{
			skillName: "bootstrap",
			fontAwesomeClassname: "fab fa-bootstrap",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "fab fa-css3-alt",
		},
		// {
		//   skillName: "sass",
		//   fontAwesomeClassname: "fab fa-sass"
		// },
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "fab fa-js",
		},
		// {
		//   skillName: "reactjs",
		//   fontAwesomeClassname: "fab fa-react"
		// },
		// {
		//   skillName: "nodejs",
		//   fontAwesomeClassname: "fab fa-node"
		// },
		// {
		//   skillName: "swift",
		//   fontAwesomeClassname: "fab fa-swift"
		// },
		// {
		//   skillName: "npm",
		//   fontAwesomeClassname: "fab fa-npm"
		// },
		{
			skillName: "sql-database",
			fontAwesomeClassname: "fas fa-database",
		},
		{
			skillName: "nosql-database",
			fontAwesomeClassname: "fas fa-database",
		},
		{
			skillName: "aws",
			fontAwesomeClassname: "fab fa-aws",
		},
		// {
		//   skillName: "firebase",
		//   fontAwesomeClassname: "fas fa-fire"
		// },
		{
			skillName: "docker",
			fontAwesomeClassname: "fab fa-docker",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "fab fa-git",
		},
	],
};

export default INFO;
