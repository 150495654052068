import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work Experience"
				btn="Details"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./tietoevry.png"
								alt="tietoevry"
								className="work-image"
							/>
							<div className="work-title">Tietoevry</div>
							<div className="work-subtitle">
								Master's Thesis Researcher
							</div>
							<div className="work-duration">
								Jan, 2024 - Jul, 2024
							</div>
						</div>

						<div className="work">
							<img
								src="./protech.jpeg"
								alt="protech"
								className="work-image"
							/>
							<div className="work-title">Protech Sh.p.k</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">
								Jul, 2021 - May, 2023
							</div>
						</div>
						<div className="work">
							<img
								src="./itworks.jpg"
								alt="itworks"
								className="work-image"
							/>
							<div className="work-title">ITWorks</div>
							<div className="work-subtitle">
								Software Engineer, Intern
							</div>
							<div className="work-duration">
								Jun, 2021 - Jul, 2021
							</div>
						</div>
						<div className="work">
							<img
								src="./ketri.jpeg"
								alt="ketri"
								className="work-image"
							/>
							<div className="work-title">Ketri Sh.p.k</div>
							<div className="work-subtitle">
								Software Engineer, Intern
							</div>
							<div className="work-duration">
								Mar, 2021 - Apr, 2021
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
